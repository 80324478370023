.prelisa_home {
  width: 100%;
  height: 100svh;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: flex-start;
  max-width: 100%;
  overflow: visible;
  padding: 15rem 5rem;
  width: calc(100% - 10rem);
  position: relative;
}

.prelisa_home .content_container {
  max-width: 1200px;
}

h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  color: #111111;
}

p {
  font-weight: 500;
  font-size: 2rem;
  color: #888888;
  letter-spacing: -0.7px;
  line-height: 1.5em;
  max-width: 66rem;
}

strong {
  font-weight: bold;
}

.prelisa_logo {
  margin-bottom: 2rem;
}

.prelisa_socials {
  display: flex;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1.5rem;
}

a {
  font-size: 1.6rem;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: #0088ff;
}

.lets_connect {
  font-size: 4rem;
  margin: 15rem 0;
}

.lets_connect_link {
  color: #888888;
  cursor: pointer;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.lets_connect_link:hover {
  color: #0088ff;
}

@media (min-width: 810px) and (max-width: 1199px) {
  * {
    font-size: 9px;
  }

  .content_container {
    width: 100%;
  }
}

@media (min-width: 630px) and (max-width: 809px) {
  * {
    font-size: 8px;
  }

  .content_container {
    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 629px) {
  * {
    font-size: 8px;
  }

  .prelisa_home {
    width: calc(100% - 6rem);
    padding: 3rem;
  }

  p {
    font-size: 2rem;
    width: 100%;
  }
}
